<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body table">
           
               <div class="main_header_ctas d-flex align-items-center justify-content-between">
          <button
              v-if="multipuleIds.length >= 1"
              @click="deleteBulk"
              class="
                btn
                border-0
                px-5
                fw-bold
                text-white
                btn btn-primary
                waves-effect waves-light
                w-md
                primary-button
              "
            >
              Delete All
            </button>
            <!-- <button
              class="
                btn
                border-0
                px-5
                fw-bold
                text-white
                btn btn-primary
                waves-effect waves-light
                w-md
                primary-button
              "
            >
              Add New List
            </button> -->
            </div>
            <div
              class="
                d-flex
                justify-content-between
                flex-sm-nowrap flex-wrap
                gap-3
                my-3
              "
            >
              <!-- <div class="d-flex justify-content-end w-100">
                <div class="plan_filter pe-2 py-0">
                  <select
                    class="form-select table-light pe-5 py-75rem"
                    id="floatingSelectGrid"
                    aria-label="select"
                  >
                    <option value="1">Paid</option>
                    <option value="2">Pending</option>
                  </select>
                </div>
              </div> -->
              <!-- <div class="search position-relative min_200">
                <input
                  class="form-control ps-5 table-light border-0"
                  list="datalistOptions"
                  id="searchList"
                  placeholder="Type to search..."
                />
                <i class="uil-search position-absolute top-0 px-3"></i>
              </div> -->
            </div>
            <div class="table-responsive">
              <table
                id="datatable"
                class="table table-centered table-nowrap mb-0 w-100"
              >
                <thead class="table-light">
                  <tr>
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          @change="selectAllRecord"
                          v-model="selectAll"
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <th>User Name</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Payment Methods</th>
                    <th>Expiry Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(payment, index) in payments" :key="index">
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          v-model="multipuleIds"
                          :value="payment.id"
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>

                    <td>
                      <div class="userDetail">
                        <div class="d-flex align-items-center">
                          
                            <img v-if="!payment.avatar"
                            src="../../../../../public/avatar.jpg"
                            class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px"
                          />
                          <img v-else
                          :src="payment.avatar"
                            class="user-img me-2"
                            alt="..."
                            width="30px"
                            height="30px"
                          />
                          
                          <span>{{ getSafe(() => payment.full_name, "N/A")  }}</span>
                        </div>
                      </div>
                    </td>
                    <td>{{ getSafe(() => payment.mobile, "N/A")   }}</td>
                    <td>{{ getSafe(() => payment.email, "N/A")  }}</td>
                    <td>{{ getSafe(() => payment.pm_type, "N/A")  }}</td>
                    <!-- <td>{{getSafe(() =>  payment.end_at, "N/A") }}</td> -->
                    <td>{{'19/2022' }}</td>
                    <td>
                      <span class="badge bg-soft-success font-size-12 w-75 p-1"
                        >Paid</span
                      >
                      <!-- <span class="badge bg-soft-warning font-size-12 w-75 p-1"
                        >Pending</span
                      > -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  page: {
    title: "Payments",
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      selectAll: false,
      multipuleIds: [],
      title: "Payments",
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Payments",
          active: true,
        },
      ],
      payments: null,
    };
  },
  mounted() {
    this.allUsers();
  },
  methods: {
    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },
    randerData(response) {
      console.log(response);
      this.payments = response.data.data;
      this.loading = false;
      if ($.fn.dataTable.isDataTable("#datatable")) {
        let table = $("#datatable").DataTable();
        table.destroy();
      }
      setTimeout(() => {
        $("#datatable").DataTable({
          scrollX:true,
        fixedHeader:{
            header: true,
            footer: true
        },
          ordering: false,
          lengthMenu: [
            [5, 10, 25, 50, -1],
            [5, 10, 25, 50, "All"],
          ],
          pageLength: 25,
        });
      });
    },
    allUsers() {
      this.$axios
        .get("payments")
        .then((response) => {
          // console.log(response.data.data);
          // this.payments = response.data.data;
          setInterval(this.randerData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.payments.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // this.$axios
          //   .post("delete-reviews-bulk", { ids: this.multipuleIds })
          //   .then((response) => {
          this.allUsers();
          Swal.fire("Deleted!", "Record has been deleted.", "success");
          this.selectAll = false;
          this.multipuleIds = [];
          //   })
          //   .catch((error) => {
          //     console.log(error.response);
          //   });
        }
      });
    },
  },
};
</script>